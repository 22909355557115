<template>

  <div class="water-for-people-map">

    <ul class="nav nav-desktop">
      <li v-for="c in locations">
        <span v-if="'•' !== c.country">
        <a href="#"
           v-on:click.prevent="selectCountry(c);"
           :style="navStyle(c)"
        >{{ c.country }}</a>
        </span>
        <span v-else class="divider"><a href="#" v-on:click.prevent="() => {return false;}">•</a></span>
      </li>
    </ul>

    <div class="nav nav-mobile">
      <div class="selected-item" v-on:click="mobile_menu = !mobile_menu">
        <span>{{ selectedCountryName }}</span>
        <a href="#" class="toggle" role="button" v-on:click.prevent=""><i class="fa fa-bars"></i></a>
      </div>
      <ul>
        <li v-for="c in locations">
        <span v-if="'•' !== c.country">
        <a href="#"
           v-on:click.prevent="selectCountry(c); mobile_menu = false;"
           :style="navStyle(c)"
        >{{ c.country }}</a>
        </span>
        </li>
      </ul>
    </div>

    <svg :viewBox="viewBox" style="stroke-linejoin: round; stroke:#FFF; fill: none;">
      <path v-for="c in countries"
            :key="c.state_abbr"
            :id="c.state_abbr"
            :d="c.path"
            :stroke-width="stroke_width"
            :style="getStyle(c)"
            v-on:click.prevent="clickCountry(c)">
      </path>
    </svg>

    <div class="container">
      <div class="info" v-html="selectedCountryContent" :style="infoStyle" :class="selectedCountry ? selectedCountry.country : ''"></div>
    </div>

    <div v-if="debug">
    <div class="slidecontainer">
      MinX
      <input type="range" min="0" max="2000" v-model="view_box.minX" class="slider">
    </div>
    <div class="slidecontainer">
      MinY
      <input type="range" min="0" max="1001" v-model="view_box.minY" class="slider">
    </div>
    <div class="slidecontainer">
      Width
      <input type="range" min="0" max="2000" v-model="view_box.width" class="slider">
    </div>
    <div class="slidecontainer">
      Height
      <input type="range" min="0" max="1001" v-model="view_box.height" class="slider">
    </div>

    {{ viewBox }}
    </div>

  </div>

</template>

<script>

// Load local service-locations.json (for dev),
// or load from window (wordpress).
let locations;
if ( window.wfpServiceLocations ) {
  locations = window.wfpServiceLocations;
} else {
  locations = require('./assets/service-locations');
}


import countries from './assets/map-data';
import $ from 'jquery';
//let $ = jQuery;

let divider = {"country": "•"};
locations.forEach((l, i) => {
  locations.splice(i*2, 0, divider);
});
locations.shift();

import {TimelineMax, Circ} from 'gsap';



export default {
  name: 'app',

  data() {
    return {
      debug: false,
      stroke_width: 1,
      stroke_color: '#fff',
      selected_country: '',
      countries: countries,
      locations: locations,
      mobile_menu: false,

      view_box: {
        minX: 401,
        minY: 311,
        width: 1121,
        height: 458
      }
    }
  },

  watch: {

    mobile_menu() {
      if ( this.mobile_menu ) {
        $('.nav-mobile ul').stop().slideDown();
      } else {
        $('.nav-mobile ul').stop().slideUp();
      }
    },

    selected_country() {
      if ( '' !== this.selected_country ) {
        $('.nav-mobile ul').stop().slideUp();
      }
    }
  },

  computed: {

    selectedCountry() {
      let c = this.selected_country, out;
      locations.forEach( l => {
        if ( l.country === c ) {
          out = l;
        }
      });
      return out;
    },

    selectedCountryName() {
      if ( '' === this.selected_country ) {
        return 'Select Country'
      }
      return this.selectedCountry.country;
    },

    viewBox() {
      let a = this.view_box.minX,
          b = this.view_box.minY,
          c = this.view_box.width,
          d = this.view_box.height;
      return `${a} ${b} ${c} ${d}`;
    },

    participatingCountries() {
      let list = [];
      locations.forEach( c => {
        list.push(c.country);
      });
      return list;
    },

    selectedCountryContent() {
      let location = this.selectedCountry;

      if ( ! location ) {
        return '';
      }

      let facts =[],
          title = `<div class="title">${this.selected_country}</div>`;

      location.facts.forEach( f => {
        facts.push(`<li>${f}</li>`);
      });

      facts = `<ul class="facts">${facts.join('')}</ul>`;

      let link = `<a class="learn-more" href="${location.slug}" v-on:click.prevent>Learn more</a>`;

      return title + facts + link;
    },

    infoStyle() {
      let location = this.selectedCountry;
      if ( location ) {
        return {backgroundColor: location.color};
      }
      return '';
    }
  },

  methods: {

    getLocationByName(countryName) {
      let out;
      locations.forEach( l => {
        if ( l.country === countryName ) {
          out = l;
        }
      });
      return out;
    },

    isParticipatingCountry(name) {
      return -1 !== this.participatingCountries.indexOf(name);
    },

    clickCountry(country) {
      if ( this.isParticipatingCountry(country.state_name) ) {
        let location = this.getLocationByName(country.state_name);
        this.selectCountry(location);
      }
    },

    navStyle(location) {
      if ( location.country === this.selected_country ) {
        return {backgroundColor: location.color, color: 'white'};
      }
      return '';
    },

    getStyle(country) {

      // Default country color.
      let color = '#E0DED8',
          clickable = false,
          current = false,
          location;

      if ( country.state_name === this.selected_country ) {
        current = true;
      }

      if ( this.isParticipatingCountry( country.state_name ) ) {
        let name = country.state_name;
        location = this.getLocationByName(name);
        color = '#4A3C31';
        clickable = true;

        // If no country is selected, show location color.
        if ( '' === this.selected_country ) {
          color = location.color;
        }
      }

      if ( current ) {
        color = location.color;
      }

      let string = `fill:${color};fill-rule:evenodd;`;

      if ( clickable ) {
        string += 'cursor: pointer;';
      }

      return string;
    },

    selectCountry(data) {

      let unselect = false;
      if ( this.selected_country === data.country ) {
        unselect = true;
      }

      let points;
      if ( ! unselect ) {
        this.selected_country = data.country;
        points = data.viewBox.split(' ');
      } else {
        points = [401, 311, 1121, 458];
        this.selected_country = '';
      }

      let from = this.view_box;

      let to = {
        minX: points[0],
        minY: points[1],
        width: points[2],
        height: points[3],
        ease: Circ.easeOut
      };

      let tl = new TimelineMax(),
          duration = 1.5;

      tl.fromTo(this.view_box, duration, from, to);
    },

    onLoad() {
      if ( ! document.URL ) {
        return;
      }
      let url = document.URL.toLowerCase();

      locations.forEach( l => {
        let name = l.country,
            lower = name.toLowerCase();
        // Check for the presence of the country name in the URL string.
        if ( -1 !== url.indexOf(`/${lower}`) ) {
          // Match country and pseudo click.
          countries.forEach( c => {
            if ( c.state_name === name ) {
              this.clickCountry(c);
            }
          });
          this.selected_country = l.country;
        }
      });
    }
  },

  mounted() {
    this.onLoad();
  }
}
</script>

<style lang="scss">

  .water-for-people-map {

    .nav {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
      display: flex;
      justify-content: space-evenly;
      justify-content: center;
      flex-wrap: wrap;
      flex-flow: wrap;
      align-content: space-evenly;

      .divider {
        a {
          padding: 10px 0;
        }
      }

      a {
        color: black;
        display: block;
        padding: 10px 5px;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 14px;

        &.active {
          color: white;
          background-color: #DD4814;
        }
      }

      @media screen and (min-width: 900px) {
        a {
          font-size: 18px;
        }
      }

      &.nav-mobile {
        display: none;
        text-align: center;

        ul, li {
          list-style: none;
          margin-left: 0;
          padding-left: 0;
        }

        a {
          margin-bottom: 0;
        }

        li {

        }
      }

      @media screen and (max-width: 800px) {
        &.nav-desktop {
          display: none;
        }
        &.nav-mobile {
          display: block;
          ul {
            display: none;
          }
        }
      }
    }

    .slider {
      -webkit-appearance: none;
      width: 45%;
      height: 15px;
      border-radius: 5px;
      background: #d3d3d3;
      outline: none;
      opacity: 0.7;
      -webkit-transition: .2s;
      transition: opacity .2s;
    }

    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #4CAF50;
      cursor: pointer;
    }

    .slider::-moz-range-thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #4CAF50;
      cursor: pointer;
    }

    .container {
      position: relative;

      .info {
        $spacing: 10px;
        position: absolute;
        bottom: $spacing;
        right: $spacing;
        padding: $spacing;
        color: white;

        .title {
          text-transform: uppercase;
          font-size: 16px;
        }


        .facts {
          display: none;
          margin: 10px 0;
          @media screen and (min-width: 768px) {
            & {
              display: block;
            }
          }
        }

        ul {
          margin: 0;
          padding-left: 15px;
          //margin: 0;
          //padding-left: 0;
        }
      }


      a {
        color: white;
        text-decoration: none;
        background-color: rgba(255, 255, 255, 0.16);
        display: block;
        padding: 5px;
        text-align: center;
        &:hover {
          background-color: rgba(255, 255, 255, 0.26);
        }
      }
    }


    .selected-item {
      font-weight: bold;
      position: relative;
      padding-bottom: 10px;
      cursor: pointer;

      span {
        text-transform: uppercase;

        position:relative;
      }
      a {
        position: absolute;
        right: 10px;
        top: -9px;
      }
    }

    svg {
      max-height: 420px;
      min-height: 320px;
      width: 100%;
    }
  }
</style>
